import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20')
];

export const server_loads = [];

export const dictionary = {
		"/(protected)": [4,[2],[3]],
		"/(protected)/application-setting": [~5,[2],[3]],
		"/(protected)/banners": [~6,[2],[3]],
		"/(protected)/benefit": [~7,[2],[3]],
		"/(protected)/brand": [~8,[2],[3]],
		"/(protected)/history-redemption": [~9,[2],[3]],
		"/(protected)/internal-account": [~10,[2],[3]],
		"/(protected)/landing-page": [~11,[2],[3]],
		"/login": [20],
		"/(protected)/outlet": [~12,[2],[3]],
		"/(protected)/popup": [~13,[2],[3]],
		"/(protected)/promotion": [~14,[2],[3]],
		"/(protected)/promotion/detail/[slug]": [~15,[2],[3]],
		"/(protected)/push-notification": [~16,[2],[3]],
		"/(protected)/segmentation": [~17,[2],[3]],
		"/(protected)/tier": [~18,[2],[3]],
		"/(protected)/user": [~19,[2],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';